import React from 'react';

import { Link } from 'react-router-dom';

import "../moreforms.css"
import DownloadLink from './DownloadLink';

function MoreFormsPage(props) {
    return (
        <div className='Form-Page More-Forms-Page'>
            <main>
                <header>
                    <span className='Page-Back'><Link to={"/"}>{"←"}</Link></span>
                    <h3>Forms</h3>
                </header>
                <div className='Form-Group'>

                    <h5>Intake Forms</h5>
                    <ul>
                        <DownloadLink text="Intake Packet for Psychological Evaluation Clients" docx={'./forms/BHS Intake Packet for Psychological Evaluation Clients 2023.docx'} pdf={'./forms/BHS Intake Packet for Psychological Evaluation Clients 2023 fillable.pdf'}/>

                        <DownloadLink text="Intake Packet for Sexual Behavior Treatment Clients" docx={'./forms/BHS Intake Packet for Sexual Behavior Treatment Clients 2023.docx'} pdf={'./forms/BHS Intake Packet for Sexual Behavior Treatment Clients 2023 fillable.pdf'}/>

                        <DownloadLink text="Intake Packet for Therapy Clients" docx={'./forms/BHS Intake Packet for Therapy Clients 2023.docx'} pdf={'./forms/BHS Intake Packet for Therapy Clients 2023 fillable.pdf'}/>
                        
                        <DownloadLink text="Intake Packet for Psychological Evaluation Clients" docx={'./forms/BHS Intake Packet for Psychological Evaluation Clients 2023.docx'} pdf={'./forms/BHS Intake Packet for Psychological Evaluation Clients 2023 fillable.pdf'}/>

                    </ul>
                </div>
                <div className='Form-Group'>
                    <h5>Other Forms</h5>
                    <ul>
                        <li><a href="./forms/BHS Authorization to Release Information.docx">Release of information form</a></li>
                        <li><a href="./forms/BHS Referral Form.docx">Referral form</a></li>
                    </ul>
                </div>
            </main>
        </div>
    );
}

export default MoreFormsPage;