import keith from "./images/keith-pic.jpg";
import jane from "./images/jane-pic_new.jpg";
import lauri from "./images/lauri-pic_new.jpg";
import lashonda from "./images/lashonda_1_orig.jpg";


export const staffPhotos = {
    keith: keith,
    jane: jane,
    lauri: lauri,
    lashonda: lashonda
}