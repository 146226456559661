import React, {useState} from 'react';

function DownloadLink({text, docx=null, pdf=null}) {

    const [displayButtons, setDisplayButtons] = useState(false);

    function linkClick(){
        setDisplayButtons(!displayButtons);
    }

    return (
        <div className='Download-Link'>
            <li><a className={displayButtons ? "Active-Download-Link":""} onMouseDown={linkClick}>{text}</a></li>
            {
                displayButtons ? 
                    <div className='Download-Button-Cont'>
                        {docx ? <a href={docx} className='Download-Button'>Download Microsoft Word Document</a>:null}
                        {pdf ? <a href={pdf} className='Download-Button' download={true}>Download PDF</a>:null}

                    </div>
                    : null
            }
        </div>
    );
}

export default DownloadLink;